/* eslint-disable */
import axios from "axios";
import {CMS} from '@/main';


const state = () => ({
    customers: [],
    sprites: {},
});

const getters = {
    allCustomers: state => state.customers,
    allSprites: state => state.sprites,
};

const actions = {

    async loadCustomers({ commit }){
        try {
            const response = await axios.get(`${CMS}api/customers`);
           // const response = await axios.get('http://localhost:8080/api/customers');
            const data = await response.data;
         /*   console.log("Response: ");
            console.log(typeof data.svg_sprite);*/
            commit('setCustomers', data.customers);
            commit('setSprites', data.svg_sprite);
        }
        catch (err) {
            throw new Error(err);
        }

    }
};

const mutations = {

    setCustomers: (state, customers) => {
        customers.forEach(customer => {
            state.customers.push(customer);
        });
    },

    setSprites: (state, sprites) => {
        state.sprites = sprites;
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}