import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import("../views/Home.vue")

    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: () => import("../views/ContactPage.vue")
    },
    {
        path: '/team',
        name: 'Team',
        component: () => import("../views/Team.vue")
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: () => import("../views/Portfolio.vue")
    },
    {
        path: "/development",
        name: "Development",
        component: () => import("../views/Development.vue")
    },
    {
        path: "/design",
        name: "Design",
        component: () => import("../views/Design.vue")
    },
    {
        path: "/support",
        name: "Support",
        component: () => import("../views/Support.vue")
    },
    {
        path: "/fyber",
        name: "Fyber",
        component: () => import("../views/casestudy/Fyber.vue"),
    },
    {
        path: "/fyberinvestors",
        name: "FyberInvestors",
        component: () => import("../views/casestudy/FyberInvestors.vue")
    },
    {
        path: "/avigora",
        name: "Avigora",
        component: () => import("../views/casestudy/Avigora.vue")

    },
    {
        path: "/careful",
        name: "Careful",
        component: () => import("../views/casestudy/Careful.vue")
    },
    {
        path: "/ciutacu",
        name: "Ciutacu",
        component: () => import("../views/casestudy/Ciutacu.vue")
    },
    {
        path: "/creeds",
        name: "Creeds",
        component: () => import("../views/casestudy/Creeds.vue")
    },
    {
        path: "/excape",
        name: "Excape",
        component: () => import("../views/casestudy/Excape.vue")
    },
    {
        path: "/summerwood",
        name: "Summerwood",
        component: () => import("../views/casestudy/Summerwood.vue")
    },
    {
        path: "/wayspa",
        name: "Wayspa",
        component: () => import("../views/casestudy/Wayspa.vue")
    },
    {
        path: "/vonino",
        name: "Vonino",
        component: () => import("../views/casestudy/Vonino.vue")
    },
    {
        path: "/pica8",
        name: "Pica8",
        component: () => import("../views/casestudy/Pica8.vue")
    },
    {
        path: "/hoinarim",
        name: "Hoinarim",
        component: () => import("../views/casestudy/Hoinarim.vue")
    },
    {
        path: "/startablish",
        name: "Startablish",
        component: () => import("../views/casestudy/Startablish.vue")
    },
    {
        path: "/psiholog",
        name: "Psiholog",
        component: () => import("../views/casestudy/Psiholog.vue")
    },
    {
        path: "/mstar",
        name: "MStar",
        component: () => import("../views/casestudy/MStar.vue")
    },
    {
        path: "/maxconcept",
        name: "MaxConcept",
        component: () => import("../views/casestudy/MaxConcept.vue")
    },
    {
        path: "/hape",
        name: "Hape",
        component: () => import("../views/casestudy/Hape.vue")
    },
    {
        path: "/bellaclean",
        name: "BellaClean",
        component: () => import("../views/casestudy/BellaClean.vue")
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach(function (to, from, next) {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
    next();
});

export default router
