/* eslint-disable */
import axios from "axios";

const state = () => ({
    notification: {
        value: undefined,
        hasBeenDisplayed: false
    },
});

const getters = {
    getNotification: state => state.notification
};

const actions = {
    // async loadEmployees({ commit }){
    //     const response = await fetch('http://localhost:8080/api/employees');
    //     const data = await response.json();
    //     commit('setEmployees', data.employees)
    // }

    fetchNotification({ commit }) {
        // const data = "This is a notification message from Vuex, nice!";
        const data = undefined;
        if (data) {
            commit('setNotification', data);
        }
    }
};

const mutations = {

    setNotification: (state, notificationMessage) => {
        state.notification.value = notificationMessage;
        state.notification.hasBeenDisplayed = true;
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}
