/* eslint-disable */
import axios from "axios";
import {CMS} from '@/main';

const state = () => ({
    partners: [],
});

const getters = {
    allPartners: state => state.partners
};

const actions = {

    async loadPartners({ commit }){
      try {
          const response = await axios.get(`${CMS}api/portfolio`);
         // const response = await axios.get('http://localhost:8080/api/portfolio');
          const data = await response.data;
          commit('setPartners', data)
      }
      catch (err) {
          throw new Error(err);
      }
    }
};

const mutations = {

    setPartners: (state, partners) => {
        partners.forEach(partner => {
            state.partners.push(partner);
        });
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}