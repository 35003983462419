<template>
  <button
      :class="['menu-icon', { 'menu-icon-active': isActive }]"
      @click.prevent="toggleNav($event)"
      type="button"
      name="menu-icon"
      aria-label="Toggle menu">
    <span></span>
  </button>
</template>

<script>
export default {

  name: 'menu-icon',

  props: ['isActive'],

  methods: {
    toggleNav($event) {
      this.$emit('click', $event);
    },
  },

}
</script>

<style lang="scss">
$easing: cubic-bezier(0.4, 0, 0.2, 1);
$timing-1: 200ms; // out
$timing-2: 480ms; // in

$menu-icon-theme: #2d98c8;
$menu-icon-theme2: #b93952;

.menu-icon {
  position: fixed;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
  z-index: 1060;
  border-color: transparent;
  pointer-events: all;
  background-color: #d9d9d9;
  transition:  background-color $timing-1 $easing;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  span {
    &, &::before, &::after {
      position: absolute;
      top: 50%;
      right: 50%;
      margin-right: -13px;
      margin-top: -1px;
      width: 27px;
      height: 2px;
      background-color: $menu-icon-theme;
      transform: rotate(135deg);
      -ms-transform: rotate(135deg);
    }

    &::before, &::after {
      content: '';
      display: block;
      transition: all $timing-1 $easing;
    }

    &::before {
      transform: translateY(-7px);
    }

    &::after {
      transform: translateY(7px);
    }
  }

  &:hover {
    background-color: $menu-icon-theme2;
  }

  &:active {
    background-color: $menu-icon-theme2;

  }

  &:focus {
    outline: none;
  }

  &.menu-icon-active {
    span {
      height: 0;

      &::before, &::after {
        width: 25px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
.v-ripple-in-enter-active {
  animation: ripple-in $timing-2 $easing;
}

.v-ripple-in-leave-active {
  animation: ripple-in $timing-1 $easing reverse;
}

@keyframes ripple-in {
  0% {
    transform: scale(0);
    opacity: .2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 1440px) {
  .menu-icon {
    span {
      &, &::before, &::after {
        margin-right: -13px;
        margin-top: -1px;
        width: 27px;
      }

      &.menu-icon-active {
        span {
          height: 0;

          &::before, &::after {
            width: 27px;
          }
        }
      }
    }
  }
}
</style>

