<template>
  <div>
    <i class="fas fa-times close-button" @click="closePanel()"/>
    <div class="privacy-policy">
      <span class="privacy-policy__title bold">PRIVACY POLICY</span>
      <span class="privacy-policy__title bold">Personal Data</span>
      <span class="privacy-policy__description">
 Personal data is information about your identity. This includes, but is not limited to
information such as name, address, telephone number, e-mail address. It is not necessary
for you to disclose personal data to use our website. However, in certain cases we will
need your name and address as well as other information to provide the requested
services, e.g., the sending of information material or the answering of individual
questions. Furthermore, we only store and process data that you provide us voluntarily
and, if applicable, data that we collect automatically when you visit our Internet pages
(e.g., your IP address and the names of the pages you call up, the browser you use and
your operating system, date and time of access, search engines used, names of
downloaded files). If you make use of our services, we only collect data necessary to
provide the services. If we ask you for further data, this is provided on a voluntary basis
to provide the requested service and to protect our own legitimate business interests.
 <br>
In general, we use the personal data you provide to answer your inquiries or provide you
with access to specific information or offers, process your job application, or comply with
legal obligations required by law. It may be necessary for us, or a service company
commissioned by us, to use this personal data to inform you about product offers or to
 conduct online surveys to enhance and personalize your website experience.
 </span>

      <p class="privacy-policy__title bold">
        Intented use
      </p>

      <div class="privacy-policy__answer">
        <p>
          We will collect, process, and use the personal data you provide online only for the
          purposes communicated to you. Your personal data will not be passed on to third parties.
          The collection of personal data and its transmission to state institutions and authorities
          entitled to receive information will only take place within the framework of the relevant
          laws or if we are obliged to do so by a court decision. Our employees and the service
          companies commissioned by us are obliged by us to maintain secrecy and to comply with
          all relevant data protection regulations.
        </p>

        <p class="privacy-policy__title bold">
          Data that is collected when you visit our website
        </p>

        <p>When you use our website, information is automatically sent to the server of our website
          by the browser used on your terminal device. This information is temporarily stored in log
          files. The following information is recorded without your intervention and stored until it
          is automatically deleted:
        </p>

        <ul>
          <li>-The address of the page of our website that you have called up.</li>
          <li>-The address of the website that you visited immediately before (the so-called "referrer").</li>
          <li>-IP address of your terminal device.</li>
          <li>-Date and time of your call.</li>
          <li>-Transfer status (file transferred, file not found, etc.).</li>
          <li>-Properties of your end device, especially the operating system, the browser used and
            the window size of your browser. <br>The legal basis for this is Art. 6 para. 1 f) of GDPR
          </li>
        </ul>

        <p>
          Furthermore, we use various analytical services when you visit our website. <br>
          If the opportunity for the input of personal or business data (email addresses, name, and
          addresses) is given, this is provided voluntarily. Your data will be treated with
          confidentially and will only be used to process your enquiry, it will not be passed to third
          parties. <br>

          The legal basis for this is Art. 6 para. 1 a) of GDPR in conjunction with your consent given
          when you send the contact form. You can revoke this consent at any time. Revocation of
          consent does not affect the lawfulness of the processing carried out based on the consent
          until revocation. There is no link to the above-mentioned access data in log files. After the
          enquiry has been processed by us, the data will first be stored in case of additional
          questions. Deletion of the data can be requested at any time, otherwise the data will be
          deleted after the matter has been completely settled. The statutory retention period of
          six years for commercial and business correspondence remains unaffected in each case,
          Art. 6 para. 1 c) of GDPR.
        </p>
      </div>

      <span class="privacy-policy__title bold">Children and young people</span>
      <p class="privacy-policy__answer">
        Persons under the age of 16 years should not transmit any personal data to us without
        the consent of their parents or legal guardians. We do not request personal data from
        children and young people, do not collect such data and do not pass it on to third parties.
        Parents or guardians are responsible for protecting the privacy of their children. Parents
        and guardians are requested to talk to their children about the safe and responsible use
        of their personal data on the Internet.
      </p>

      <span class="privacy-policy__title bold">Safety and personal data recipients</span>
      <p class="privacy-policy__answer">
        We have taken technical and organisational security measures to protect your personal
        data from loss, destruction, manipulation, and unauthorised access. All our employees
        and all persons involved in data processing are obliged to comply with the laws relevant
        to data protection and to handle personal data confidentially. If personal data is collected
        and processed, the information is transmitted in encrypted form to prevent misuse of the
        data by third parties. <br>
        Our security measures are continuously revised in line with technological developments.
      </p>


      <span class="privacy-policy__title bold">Social media channels</span>
      <p class="privacy-policy__answer">
        On our website you will find links to social networks. You can recognise these links by the
        logos of the respective providers. Clicking on the links will redirect you to the
        corresponding social media sites. <br>
        Before calling up the corresponding links, no personal information is transmitted to the
        respective providers.
      </p>

      <span class="privacy-policy__title bold">Changes to this policy</span>
      <p class="privacy-policy__answer">
        We reserve the right to change our security and data protection measures if this becomes
        necessary due to technical developments. In these cases, we will also adapt our data
        protection information accordingly. Therefore, please note the current version of our
        data protection declaration. We will communicate any changes to you, where we are
        required to do so.
      </p>

      <span class="privacy-policy__title bold">Right to object</span>
      <p class="privacy-policy__answer">
        If you think it is justified by a good reason, in certain circumstances you have the right to
        object to the processing of your Personal data, if the legal requirements are met. To
        declare your objection, you can contact us by e-mail at: <b class="privacy-policy__color">contact@scriptics.ro</b>
      </p>

      <span class="privacy-policy__title bold">GDPR Policy</span>
      <p class="privacy-policy__answer">
        This Privacy Statement is for natural persons who are entitled under the General Data
        Protection Regulation (GDPR).
      </p>

      <span class="privacy-policy__subtitle bold">1.Collection, Use, Provision, and Entrustment of Personal Information</span>
      <p class="privacy-policy__answer">To this Privacy Statement, the following terms have the meanings as defined below.<br>
        (a) ‘Personal Information’ means any data relating to an identified or identifiable natural
        person, including but not limited to your names, addresses, birth dates, telephone
        numbers, e-mail addresses, user IDs, IP addresses, web beacons and other online
        identifiers.</p>
      <p class="privacy-policy__answer">(b) ‘Processing’ means any operation or set of operations which is performed on Personal
        Information or on sets of Personal Information, whether by automated means, such as
        collection, recording, organisation, structuring, storage, adaptation or alteration,
        retrieval, consultation, use, disclosure by transmission, dissemination or otherwise
        making available, alignment or combination, restriction, erasure, or destruction.</p>
      <p class="privacy-policy__answer">(1) Personal information shall be collected only after informing the customers of
        the purpose and obtaining consent for doing so, except when the rights or interests of
        customers or third parties may be injured. The scope of use of the personal information <br>
        collected is limited, and the information shall be handled appropriately within this scope.
        (2) Scriptics Decisions will process your Personal Information to achieve the
        following goals: <br>
        - Scriptics Decisions will process Data subjects’ Personal Information collected from
        the contact form on our Site to respond to your inquiries, questions, and/or other
        requests. <br>
        - Scriptics Decisions may process Data subjects’ Personal Information collected by
        Cookies for automated decision-making including profiling in order to provide the services
        which may be of particular interest to you and recognize your computer when visiting the
        website for improving the website's usability.For more information please refer to the
        <b>Cookie Policy.</b><br>
        In case of processing your Personal Information for purposes other than the above,
        Scriptics Decisions will notify you in advance of such new purposes of use and other
        matters as required by applicable law.
      </p>


      <span class="privacy-policy__subtitle bold">2.Disclosure, Correction, Discontinuance of Use, etc. of Personal Information</span>
      <p class="privacy-policy__answer">The Users may request from Company access to, rectification or erasure of, restriction of processing
        of personal data, and may request the data portability. You may object to the data
        protection authorities with jurisdiction over Scriptics Decisions or the location of your
        domicile regarding the processing of your Personal Information.</p>

      <span class="privacy-policy__subtitle bold">3.Retention Period</span>
      <p class="privacy-policy__answer">Scriptics Decisions will retain your Personal Information if we require
        such data to provide the Services to you but will promptly delete the same in case that
        such data is no longer necessary.</p>

      <span class="privacy-policy__subtitle bold">4.Continuous improvements to this Personal Information Protection Policy and In-house </span>
      <p class="privacy-policy__answer">Rules and Regulations Scriptics Decisions makes continuous improvements to this
        Personal Information Protection Policy and in-house rules and regulations to ensure that
        the protection of personal information as determined by socially accepted norms is
        conducted effectively.</p>

      <span class="privacy-policy__subtitle bold">5.Data Protection Officer </span>
      <p class="privacy-policy__answer">Scriptics Decisions has appointed the data protection officer
        for you to contact if you have any questions or concerns about the Scriptics Decisions’s
        personal data policies or practices. The contact information are as follows: <br>
        E-MAIL:<b class="privacy-policy__color"> ionel.tera@scriptics.ro</b></p>


    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyPanel",

  data() {
    return {}
  },
  methods: {
    closePanel() {
      this.$emit("closePanel", {});
    },
  }
}
</script>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  top: 5px;
  left: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #fafafa;
  z-index: 10002;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

}

.privacy-policy {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  color: #333;
  margin: 10%;
  padding: 20px;
  border-radius: 20px;
  line-height: 1.5em;
  -webkit-box-shadow: 0 0 15px -4px #ffffff;
  box-shadow: 0 0 15px -4px #ffffff;

  span {
    pointer-events: none;
  }

  .bold {
    font-weight: bold;
  }

  &__title {
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 1.5em;
  }

  &__color {
    color: #e3b440;
  }

  &__subtitle {
    font-size: .9em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__accept {
    margin: 20px 0 25px;
  }

  &__answer, &__accept, &__lists, &__description {
    font-size: 1em;
  }

  &__question {
    font-size: 1.5em;
    margin-top: 35px;
    margin-bottom: 6px;
  }

  &__answer {
    margin-bottom: 20px;

    p {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
}

ul {
  padding: 25px 0;
}
</style>
