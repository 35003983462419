<template>
  <transition
      :name="transitionName"
      @after-enter="afterTransitionEnter"
      @before-leave="beforeTransitionLeave">

    <div v-show="show"
         :class="menuClasses"
         :style="transitionStyle">

      <div class="overlay-menu-inner">
        <img id="trianglesHero4" :src="trianglesHero4" alt="trianglesHero4">
        <img id="trianglesHero5" :src="trianglesHero5" alt="trianglesHero5">
        <img id="heroMenu" :src="heroMenu" alt="Triangles Hero Compound">

        <div class="overlay-menu-ripple" :style="rippleStyle"></div>
        <div ref="content" class="overlay-menu-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>
import trianglesHero4 from '@/assets/triagnles-hero-pc4.svg';
import trianglesHero5 from '@/assets/triagnles-hero-pc5.svg';
import menuHeros from '@/assets/triagnles-hero-compound.svg';
import $ from 'jquery'


export default {

  name: 'overlay-menu',

  props: {
    toggleEvent: {
      required: true
    },
    transitionName: {
      type: String,
      default: 'v-ripple-in'
    }
  },

  data() {
    return {
      trianglesHero4: trianglesHero4,
      trianglesHero5: trianglesHero5,
      heroMenu: menuHeros,
      show: false,
      hasTransitioned: false,
      rippleOriginX: 0,
      rippleOriginY: 0,
      rippleSize: 0,
      lastScroll: 0,
      initialScrollBehavior: getComputedStyle(document.documentElement).getPropertyValue('scroll-behavior')
    }
  },

  computed: {
    allowShow() {
      return (this.show);
    },
    transitionStyle() {
      return {
        'transform-origin': `${this.rippleOriginX} % ${this.rippleOriginY}%`
      };

    },
    menuClasses() {
      return [
        'overlay-menu',
        {'overlay-menu-active': this.hasTransitioned}];
    },
    rippleStyle() {
      return {
        height: `${this.rippleSize}px`,
        width: `${this.rippleSize}px`

      };
    }
  },

  watch: {
    toggleEvent($event) {
      this.show = !this.show;
      this.setRipple($event);
    }
  },

  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.show = false;
        this.$emit('closeMenu');
      }
    })
  },

  methods: {
    setRipple({clientX, clientY}) {
      const {max, ceil, floor} = Math;

      const vw = document.documentElement.clientWidth;
      const vh = window.innerHeight;

      this.rippleSize = max(ceil(vw), ceil(vh)) * 1.5;
      this.rippleOriginX = floor(this.rangePercentage(clientX, 0, vw));
      this.rippleOriginY = floor(this.rangePercentage(clientY, 0, vh));
    },

    rangePercentage(x, min, max) {
      return (x - min) * 100 / (max - min);
    },

    afterTransitionEnter() {
      if (!this.allowShow) return;

      if (this.initialScrollBehavior !== 'auto') {
        document.documentElement.style.setProperty('scroll-behavior', 'auto');
      }
      this.hasTransitioned = true;

      let scrollPosition = [
        self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
        self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
      ];
      let html = $('html');
      html.data('scroll-position', scrollPosition);
      html.data('previous-overflow', html.css('overflow'));
      html.css('overflow', 'hidden');
      window.scrollTo(scrollPosition[0], scrollPosition[1]);

    },

    beforeTransitionLeave() {
      let html = $('html');
      let scrollPosition = html.data('scroll-position');
      html.css('overflow', html.data('previous-overflow'));
      window.scrollTo(scrollPosition[0], scrollPosition[1])

      if (this.initialScrollBehavior !== 'auto') {
        document.documentElement.style.setProperty('scroll-behavior', this.initialScrollBehavior);
      }
      this.hasTransitioned = false;
    },

  }

}
</script>

<style scoped lang="scss">
$easing: cubic-bezier(0.4, 0, 0.2, 1);
$timing-1: 200ms; // out
$timing-2: 480ms; // in

.overlay-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: transform;
  z-index: 1050;
  background-color: #fafafa;
}

.overlay-menu-content {
  position: relative;
}

.overlay-menu-inner {
  position: relative;
  display: flex;
  justify-content: center;
}

.overlay-menu-ripple {
  position: absolute;
  background: url("../../assets/hero-blurred-expanded-menu.webp") no-repeat;
  background-size: 100% 65%;
}

#trianglesHero4 {
  position: absolute;
  top: 32em;
  height: 30%;
  left: 50em;
  z-index: 3;
}

#trianglesHero5 {
  position: absolute;
  top: 46em;
  height: 35%;
  right: 101em;
  z-index: 3;
}

#heroMenu {
  display: none;
}

.v-ripple-in-enter-active {
  animation: ripple-in $timing-2 $easing;
}

.v-ripple-in-leave-active {
  animation: ripple-in $timing-1 $easing reverse;
}

@keyframes ripple-in {
  0% {
    transform: scale(0);
    opacity: .2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 1990px) {
  #trianglesHero4 {
    top: 24em;
    left: 40em;
  }
  #trianglesHero5 {
    position: absolute;
    top: 38em;
    right: 78em;
  }
}

@media screen and (max-width: 1440px) {
  #trianglesHero4 {
    top: 20em;
    left: 29em;
  }
  #trianglesHero5 {
    right: 54em;
  }
}

@media screen and (max-width: 1024px) {
  #trianglesHero4 {
    top: 23em;
    height: 25%;
    left: 23em;
  }
  #trianglesHero5 {
    top: 35em;
    right: 35em;
  }
}

@media screen and (max-width: 768px) {
  #trianglesHero4 {
    top: 23em;
    height: 20%;
    left: 18em;
  }
  #trianglesHero5 {
    top: 33em;
    right: 25em;
  }
}

@media screen and (max-width: 425px) {
  .overlay-menu-content {
    width: 80%;
    margin: 0 auto;
  }
  .overlay-menu-inner {
    background-position: 100%;
  }
  #trianglesHero4 {
    display: none;
  }
  #trianglesHero5 {
    display: none;
  }
  .overlay-menu-inner {
    height: 100%;
  }
  #heroMenu {
    display: block;
    top: 0;
    right: 0;
    position: absolute;
    -webkit-transform: scale(2.8);
    z-index: 3;
  }
  .overlay-menu-ripple {
    background-size: 76% 97%;
  }
}

</style>
