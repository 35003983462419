<template>
  <div id="app">
    <Menu></Menu>
    <main class="main">
      <router-view></router-view>
    </main>

  </div>

</template>

<script>

import Menu from "./components/Menu/Menu";

export default {
  name: 'App',
  components: {Menu}
};
</script>

<style lang="scss">

#app {
  font-family: "Heebo", sans-serif;
 // font-family: 'Montserrat', sans-serif;
  outline: none;
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: "Heebo", sans-serif;
  //font-family: 'Montserrat', sans-serif;
}

//contact page styles
.contact-us {
  padding: 75px 0 !important;
  display: flex;
  align-content: center!important;
  justify-content: center!important;
}
//case study pages style
.hero-case-study {
  margin-top: 100px;
  position: relative;
  border: none;
}

.grid-container {
  max-width: 1400px;
  width: 80%;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
}

.grid-container::after {
  clear: both;
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden
}

.grid-container p {
  letter-spacing: .35px;
  font-size: 24px;
}

.hero__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
  padding: 100px 0 82px;
  display: block;
  clear: both
}

.hero__content img {
  max-width: 420px;
  display: block;
  clear: both;
  width: 100%;
  margin: 0 auto
}

.hero__content .sep {
  border: none;
  clear: both;
  display: block;
  height: 1px;
  margin: 90px auto 80px;
  max-width: 780px;
  background: rgb(204, 204, 204);
  background: -moz-linear-gradient(90deg, rgba(204, 204, 204, 1) 21%, rgba(28, 28, 28, 1) 53%, rgba(204, 204, 204, 1) 96%);
  background: -webkit-linear-gradient(90deg, rgba(204, 204, 204, 1) 21%, rgba(28, 28, 28, 1) 53%, rgba(204, 204, 204, 1) 96%);
  background: linear-gradient(90deg, rgba(204, 204, 204, 1) 21%, rgba(28, 28, 28, 1) 53%, rgba(204, 204, 204, 1) 96%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cccccc", endColorstr="#cccccc", GradientType=1);

}

.hero__content p,
.laptop-img img,
.cs-ty {
  display: block;
  margin: 0 auto;
  clear: both
}

.hero__content p {
  font-family: Lato-Light, sans-serif;
  color: #686868;
  font-size: 23px;
  text-align: center;
  max-width: 990px;
  width: 100%;
  float: none;
  line-height: 28px;
}

.laptop-img {
  padding: 90px 0 94px;
  display: block;
  clear: both
}

.laptop-img img {
  max-width: 900px;
  width: 100%
}

.laptop-img {
  padding: 90px 0 94px;
  display: block;
  clear: both;
}

.container-about h3,
.container-about p {
  text-align: left;
  color: #2c2c2c;
  font-family: Lato-Light, sans-serif
}

.container-about {
  background: #fafafa;
  padding: 0 0 150px;
  display: block;
  clear: both
}

.container-about img {
  max-width: 400px;
  display: block;
  float: right;
  width: 38%
}

.container-about h3 {
  font-size: 50px;
  padding-top: 100px;
  padding-bottom: 66px;
  width: 60%;
  float: left
}

.container-about p {
  font-size: 28px;
  line-height: 32px;
  width: 60%;
  float: left
}

.container-about h3, .container-about p {
  text-align: left;
  color: #56595c;
  font-weight: 200;
}

.container-typography {
  padding: 10vh 0;
}

.container-typography__content .black,
.container-typography__content h3,
.container-typography__content h4 {
  color: #2d2d2d
}

.container-typography__content, container-nav-projects__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.container-typography__content h3 {
  font-size: 50px;
  text-align: center;
  font-family: Lato-Light, sans-serif;
  padding-bottom: 88px;
  padding-top: 100px;
  width: 100%;
}

.container-typography__content h4 {
  padding: 30px 0;
  font-size: 28px;
}

.container-typography__content img {
  display: inline;
  clear: both;
  margin-right: 10px;
}

.container-typography__content h3 span {
  font-size: 50px;
  text-align: center;
  font-family: Lato-Light, sans-serif;
}

.colors {
  width: 34%;
  float: left;
  margin-bottom: 150px;
}

.color-tag {
  height: 260px;
}

.font-typo img {
  width: 100%;
  max-width: 758px;
}

.fcolor, .scolor, .tcolor {
  height: 43px;
  width: 100%;
  max-width: 300px;
  color: #fff;
  margin-bottom: 30px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding-top: 10px;
  display: block;
  float: right;
  font-size: 16px;
}

.color-codes {
  width: 46%;
  float: right;
}

.color-codes, .colors {
  display: inline-block;
}

.color-codes, .colors {
  display: inline-block;
}

.ccodes, .colors {
  margin-top: 60px;
}

.ccodes p {
  text-align: left;
  margin-bottom: 50px;
  font-size: 24px;
}

.ccodes p {
  width: 100%;
  display: block;
}

.vertical {
  background: #c1c1c1;
  margin: 20px auto 0;
  height: 430px;
  display: inline-block;
  width: 1px;
}

.color-tag, .fcolor, .font-typo, .scolor, .tcolor {
  text-align: center;
}

.color-tag {
  height: 260px;
}

.color-tag, .fcolor, .font-typo, .scolor, .tcolor {
  text-align: center;
}

.testimonial-text {
  font-size: 18px;
  padding-bottom: 14px;
  color: #fafafa;
  text-align: center;
  font-weight: 400;
}

.testimonial-title-text {
  padding-bottom: 14px;
  font-size: 20px;
  font-weight: 400;
}

.testimonial-text__content {
  font-size: 20px;
  line-height: 26px;
  width: 100%;
  padding-bottom: 32px;
  max-width: 1024px;
  margin: 0 auto;
  display: block;
  float: none;
  font-weight: 400;
}

.doublefont {
  padding-bottom: 50px;
}

.container-nav-projects {
  padding: 5% 0;
}

.container-nav-projects__content, container-services__content {
  margin: 25px 0;
}

.container-nav-projects__content h3, .container-services__content h3 {
  text-align: center;
  margin: 10vh 0;
  font-weight: 700;
  font-size: 55px;
  font-family: Lato-Light, sans-serif;
  padding: 7vh 0 100px;
}

.container-services__content h4 {
  padding: 30px 0;
  font-size: 28px;
}

.container-services__content h3, .container-services__content h4 {
  color: #2d2d2d;
}

.wwd-left {
  text-align: right;
  float: left;
  width: 46%;
  margin-right: 35px;
}

.vertical {
  display: inline-block;
  width: 1px;
  background: #c1c1c1;
  margin-top: 20px;
  height: 430px;
}

.right {
  margin-right: 7%;
}

.wwd-right {
  display: inline-block;
  text-align: left;
  float: right;
  width: 48%;
  margin-left: 20px;
  margin-top: 26px;
}

.container-nav-projects-left {
  float: left;
  max-width: 550px;

  &:hover {
    transform: translateY(-20px);
    transition: all 0.5s ease;
    opacity: .8;
  }
}

.container-nav-projects-right {
  float: right;
  max-width: 550px;

  &:hover {
    transform: translateY(-20px);
    transition: all 0.5s ease;
    opacity: .8;
  }
}

.container-nav-projects-left img, .container-nav-projects-right img {
  width: 100%;
  max-width: 550px;
  margin-right: 10px;
}

@media only screen and (max-width: 1366px) {
  .grid-container {
    padding-right: 4%;
    padding-left: 4%;
  }
}

@media screen and (max-width: 768px) {
  .container-about h3, .container-about p {
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .hero__content {
    width: 90%;
  }
  .container-about img {
    width: 65%;
  }
  .container-about p {
    font-size: 18px;
  }
  .container-about h3, .container-about p {
    width: 100%;
  }
  .container-nav-projects-left, .container-nav-projects-right {
    float: none;
    max-width: 550px;
  }
}

@media only screen and (max-width: 375px) {
  .container-services__content h4 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 330px) {
  .container-typography__content h3 {
    font-size: 40px;
  }
}
</style>
