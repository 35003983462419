<template>
  <section class="partners-container" v-if="isMounted && hasContentLoaded">
<!--    <div class="container-separator-line"></div>-->
    <div class="customers-container">
      <div class="animatedHeadline">
        <section class="cd-intro">
          <h1 class="cd-headline rotate-1">
            Partners <br/> &amp;
            <span class="cd-words-wrapper">
                                         <b class="is-visible">Clients</b>
                                         <b class="is-hidden">Friends</b>
            </span>
          </h1>
        </section>
      </div>

      <div class="lineAndLogo_line">
        <img src="../../assets/scriptics-logo-light.svg" alt="logo">
      </div>

      <div class="container_customersImages">
        <div class="customers-img-1">
          <div class=" customers-opacity wayspa" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity fyber" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class=" customers-opacity rnts" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity hape" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
        </div>
        <div class="customers-img-2">
          <div class=" customers-opacity summerwood" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity vonino" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class=" customers-opacity pica8" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity davino" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class=" customers-opacity beleduc" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
        </div>
        <div class="customers-img-3">
          <div class="customers-opacity exclusive-vets" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity alien-wine" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity vorbe-grele" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
          <div class="customers-opacity indirim" :style="{ backgroundImage: 'url(' +link + allSprites.romb_svg + ')'}"></div>
        </div>
        <div class="customers-img-mobile" >
          <img :src="`${link}${allSprites.mobile_svg}`">
        </div>
      </div>
    </div>
    <div class="laptop-slider-grid">
      <div class="laptop-slider-content__title">Featured Projects</div>

      <div class="laptop-slider-content" :style="changeThemeColor">
        <div class="full-width slider" v-if="carouseIsShow">
          <transition-group class="slider_screen" :enter-active-class="enterActiveClass" >
            <div class="slider-box"
                 v-for="(item, index) in carouselList"
                 :key="item.id"
                 :class= "[index === currentIndex ? 'opaque' : 'notOpaque']"
                 >

              <div class="slider-box__item">
                <img :src="item.img" width="100%" height="100%"/>
              </div>
            </div>
          </transition-group>
        </div>
        <div class="timeline" :class="{mt: !carouseIsShow}">
          <div class="line full-width" style="border-radius: 50%"></div>
          <div class="timeline-container">
            <div class="item" @click="go(prevIndex, 'prev')">
              <button :class="{ 'item-disabled': !loop && currentIndex === 0 }"
                      class="item-prev"
                      :disabled="!loop && currentIndex === 0">
                <i class="fas fa-chevron-left" aria-hidden="true"></i>
              </button>
            </div>
            <div class="item">
              <div class="list"
                   v-for="(item, index) in timelineList"
                   :key="item.id"
                   @click="go(index, '')"
                   @mouseover="handleMouseOver(item.text, index)"
                   @mouseleave="handleMouseLeave(item.text, index)"
                   :style="{ transform: `translateX(${translateNum}px)`,width: `${timelineWidth}px`}">

                <div class="circle"
                     :class="{ active: index === currentIndex }">
                </div>
                <div class="line"
                     :class="{   on: index === currentIndex && onIsActive,
                                 go: index === currentIndex && !onIsActive,}">
                </div>
                <div class="text">
                  {{ item.text }}
                </div>
                <div ref="tip" class="tip">
                  {{ item.text }}
                  <div class="triangle"></div>
                </div>
              </div>
            </div>
            <div class="item" @click="go(nextIndex, 'next')">
              <button
                  class="item-next"
                  :disabled="!loop && currentIndex === timelineList.length - 1"
                  :class="{'item-disabled':!loop && currentIndex === timelineList.length - 1}">
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



<!--    <div class="review-clutch">-->
<!--      <div class="review-clutch-title">What our <br> clients say</div>-->
<!--      <div class="review-clutch__content">-->
<!--        <div class="clutch-widget"-->
<!--             data-url="https://widget.clutch.co"-->
<!--             data-widget-type="1"-->
<!--             data-height="40"-->
<!--             data-theme="navy"-->
<!--             data-expandifr="true"-->
<!--             data-clutchcompany-id="283502">-->
<!--        </div>-->
<!--        <h1 class="clutch-widget-text">Space for reviews here</h1>-->
<!--      </div>-->
<!--    </div>-->
  </section>

</template>

<script>
import $ from "jquery";
import {mapActions, mapGetters} from "vuex";
import { CMS } from '@/main';

export default {

  name: "Partners",

  props: {
    isMounted:{
      type: Boolean,
      default: false
    },
    timelineList: {
      type: Array,
      default() {
        return [
          {
            id: 1,
            text: "Fyber",
          },
          {
            id: 2,
            text: "Wipak",
          },
          {
            id: 3,
            text: "Tactical",
          },
          {
            id: 4,
            text: "Ujet",
          },
          {
            id: 5,
            text: "Weq",
          },
          {
            id: 6,
            text: "Sw",
          },
          {
            id: 7,
            text: "MStar",
          },
          {
            id: 8,
            text: "Eg",
          },
        ];
      },
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    carouselList: {
      type: Array,
      default() {
        return [
          {
            id: 1,
            img: require('../../assets/laptop-slider/fyber.webp'),
          },
          {
            id: 2,
            img: require('../../assets/laptop-slider/wipak.webp')
          },
          {
            id: 3,
            img: require('../../assets/laptop-slider/tactical.webp')
          },
          {
            id: 4,
            img: require('../../assets/laptop-slider/ujet.webp')
          },
          {
            id: 5,
            img: require('../../assets/laptop-slider/weq.webp')
          },
          {
            id: 6,
            img: require('../../assets/laptop-slider/sw.webp')
          },
          {
            id: 7,
            img: require('../../assets/laptop-slider/mstar.webp')
          },
          {
            id: 8,
            img: require('../../assets/laptop-slider/eg.webp')
          }
        ]
      }
    },
    timelineNum: {
      type: Number,
      default: 6,
    },
    timelineWidth: {
      type: Number,
      default: 180,
    },
    themeColor: {
      type: String,
      default: "#1c1c1c",
    },
    enterActiveClass: {
      type: String,
      default: 'animate__animated animate__fadeInLeft',
    },
    mouseEvent: {
      type: Boolean,
      default: false,
    },
    carouseIsShow: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      currentIndex: 0,
      timer: null,
      timer2: null,
      onIsActive: true,
      translateNum: 0,
      link: CMS,
      hasContentLoaded: false,

    }
  },

  computed: {
    ...mapGetters(["allCustomers", 'allSprites']),
    prevIndex() {
      if (!this.loop) {
        if (this.currentIndex == 0) {
          return 0;
        } else {
          return this.currentIndex - 1;
        }
      } else {
        if (this.currentIndex == 0) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex - 1;
        }
      }
    },
    nextIndex() {
      if (!this.loop) {
        if (this.currentIndex == this.timelineList.length - 1) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex + 1;
        }
      } else {
        if (this.currentIndex == this.timelineList.length - 1) {
          return 0;
        } else {
          return this.currentIndex + 1;
        }
      }
    },
    changeThemeColor() {
      return {
        "--theme-color": this.themeColor,
      };
    },
  },

  methods: {
    ...mapActions(['loadCustomers']),

    go(index, str) {
      this.currentIndex = index;
      this.onIsActive = true;
      if (this.autoPlay) {
        this.setGo();
        this.setOnActive();
      }
      this.calcTranslateNum(str);
      this.$emit("getCurrentItem", index, this.timelineList[index], this.carouselList[index]);
    },
    setOnActive() {
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.onIsActive = false;
      }, 3800);
    },
    setGo() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.go(this.nextIndex);
        this.calcTranslateNum("next");
      }, 5600);
    },
    calcTranslateNum(str) {
      const length = this.timelineList.length - 1;
      if (str === "next") {
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum = (length - this.timelineNum) * -this.timelineWidth;
        }
        if (this.currentIndex === 0) {
          this.translateNum = 0;
        }
      } else if (str === "prev") {
        if (this.currentIndex <= this.timelineNum) {
          this.translateNum = 0;
          return;
        }
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum =
              (length - this.timelineNum) * -this.timelineWidth;
          return;
        }
        this.translateNum += this.timelineWidth;
      } else {
        if (this.currentIndex < this.timelineNum) {
          this.translateNum = 0;
          return;
        }
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum =
              (length + 1 - this.timelineNum) * -this.timelineWidth;
          return;
        }
        this.translateNum += this.timelineWidth;
      }
    },
    handleMouseOver(text, index) {
      if (text.length >= 16) {
        this.$refs.tip[index].style = "display: block;";
      }
    },
    handleMouseLeave(text, index) {
      if (text.length >= 16) {
        this.$refs.tip[index].style = "display: none;";
      }
    },
    handleSliderMouseOver() {
      if (this.autoPlay && this.mouseEvent) {
        clearInterval(this.timer);
        clearTimeout(this.timer2);
      }
    },
    handleSliderMouseLeave() {
      if (this.autoPlay && this.mouseEvent) {
        this.setGo();
        this.setOnActive();
      }
    },
  },

  mounted() {
    if (this.autoPlay && this.isMounted) {
      this.setGo();
      this.setOnActive();
    }
    this.$emit("getCurrentItem", 0, this.timelineList[0], this.carouselList[0]);

    //animated headlines clients & friends
    var animationDelay = 2500;
    animateHeadline($('.cd-headline'));

    function animateHeadline($headlines) {
      $headlines.each(function () {
        var headline = $(this);
        setTimeout(function () {
          hideWord(headline.find('.is-visible'))
        }, animationDelay);
      });
    }

    function hideWord($word) {
      var nextWord = takeNext($word);
      switchWord($word, nextWord);
      setTimeout(function () {
        hideWord(nextWord)
      }, animationDelay);
    }

    function takeNext($word) {
      return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
    }

    function switchWord($oldWord, $newWord) {
      $oldWord.removeClass('is-visible').addClass('is-hidden');
      $newWord.removeClass('is-hidden').addClass('is-visible');
    }

    singleLetters($('.cd-headline.letters').find('b'));

    function singleLetters($words) {
      $words.each(function () {
        var word = $(this),
            letters = word.text().split(''),
            selected = word.hasClass('is-visible');
        for (let i in letters) {
          letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>' : '<i>' + letters[i] + '</i>';
        }
        var newLetters = letters.join('');
        word.html(newLetters);
      });
    }

    // function isScrolledIntoViewTitle(elem) {
    //   var docViewTop = $(window).scrollTop();
    //   var docViewBottom = docViewTop + $(window).height();
    //
    //   var nav = $('.laptop-slider-grid');
    //
    //   if (nav.length) {
    //     var elemTop = nav.offset().top;
    //   }
    //
    //   var elemBottom = elemTop + $(elem).height();
    //   return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    // }
    //
    // $(document).on('scroll', function () {
    //   if (isScrolledIntoViewTitle($('.laptop-slider-grid'))) {
    //     // console.log('see container');
    //
    //     const element1 = document.querySelector(".laptop-slider-content__title");
    //     element1.classList.add("animated", "slideInUp");
    //
    //   }
    // });
  },

  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timer2);
  },

  async created() {
    await this.loadCustomers();
    this.hasContentLoaded = true;
  }
}
</script>

<style lang="scss">
.slider_screen{
  height: 410px;
}
.notOpaque{
  opacity: 0;
  animation: fadeOut linear 2s;
  -webkit-animation: fadeOut linear 2s;
  -moz-animation: fadeOut linear 2s;
  -o-animation: fadeOut linear 2s;
  -ms-animation: fadeOut linear 2s;
}
.opaque{
  opacity: 1;
  animation: fadeIn linear 7s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

.container-separator-line {
  width: 100%;
  height: 4px;
  background: #2d98c8;
  background: linear-gradient(122deg, #2d98c8 64%, #fafafa 77%);
}

.partners-container {
  background-color: #fafafa;
  display: block;
}

.container-separator-line {
  width: 100%;
  height: 4px;
  background: #2d98c8;
  background: linear-gradient(122deg, #2d98c8 64%, #fafafa 77%);
  webkit-box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
  -moz-box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
  box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
}

.laptop-slider-grid {
  max-width: 1400px;
  width: 80%;
  margin: 0 auto 30px auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;


  .laptop-slider-content__title {
    text-align: center;
    margin: 12vh 0;
    font-size: 65px;
    font-weight: 900;
    background: rgb(58, 58, 58);
    background: linear-gradient(125deg, rgba(58, 58, 58, 1) 56%, rgba(45, 152, 200, 1) 64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .laptop-slider-content {


    .slider {
      padding: 7% 0;
      transform: scale(1.5);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      span {

      }

      .leave-to {
        position: relative;
      }

      .slider-box {
       position: absolute;
        width: 100%;
        left:0;
        align-items: center;


        .slider-box__item {
          width: 50%;
          margin: 0 auto;
          height: 100%;

          &:nth-child(2) {
            padding-left: 10%;
            box-sizing: border-box;
            position: relative;

            .item-title-bg {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .timeline {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 20px;

      &.mt {
        margin-top: 80px;
      }

      .line {
        width: 100%;
        height: 3px;
        background: rgb(69, 155, 201);
        background: linear-gradient(122deg, rgba(69, 155, 201, 1) 29%, rgba(122, 104, 170, 1) 56%, rgba(200, 35, 124, 1) 76%);
      }

      .timeline-container {
        width: 100%;

        .item {
          width: 10%;
          float: left;
          text-align: center;
          color: rgb(69, 155, 201);
          font-weight: 500;

          &:nth-child(2) {
            width: 75%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            padding-top: 150px;
            margin-top: -150px;
          }

          .list {
            position: relative;
            text-align: center;
            cursor: pointer;
            transition: 0.5s all linear;
            display: flex;
            justify-content: center;

            &:last-child {

              .line {
                display: none;
              }
            }

            .circle {
              position: absolute;
              left: 48%;
              width: 15px;
              height: 15px;
              background: rgb(69, 155, 201);
              border-radius: 50%;
              margin: -8px auto 0;
              z-index: 2;

              &.active {
                background-color: #1c1c1c;
              }

              &:hover {
                background-color: var(--theme-color);
              }
            }

            .line {
              width: 100%;
              height: 2px;
              box-shadow: 2px 2px 2px 0 #828282;
              position: absolute;
              left: 48%;
              top: -2px;
              opacity: 0;
              z-index: 1;

              &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: #1c1c1c;
                animation-fill-mode: both;
                animation-duration: 2.8s;
                animation-timing-function: linear;
              }
            }

            .line.on {
              opacity: 1;

              &::after {
                animation-name: line_1;
              }
            }

            .line.go {
              opacity: 1;

              &::after {
                right: 0;
                left: auto;
                animation-duration: 1s;
                animation-name: line_2;
              }
            }

            .text {
              margin-top: 15px;
              width: 115px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              position: relative;
            }

            .tip {
              position: absolute;
              top: -55px;
              padding: 10px 20px;
              border-radius: 5px;
              background-color: var(--theme-color);
              color: #fff;
              width: max-content;
              display: none;

              .triangle {
                width: 0;
                height: 0;
                border-top: 8px solid var(--theme-color);
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                position: absolute;
                left: 50%;
                bottom: -8px;
                margin-left: -3.5px;
              }
            }
          }

          .item-prev,
          .item-next {
            position: relative;
            top: 53px;
            width: 45px;
            height: 45px;
            color: #1c1c1c;
            cursor: pointer;
            margin: -43px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);

            &:hover {
              color: rgb(69, 155, 201);
            }

            &.item-disabled {
              background: #666;
              cursor: no-drop;
            }

            i {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
}

@keyframes line_1 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes line_2 {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.customers-container {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 150px;
  overflow: hidden;
}

.animatedHeadline {
  display: inline-block;
  float: left;
  width: 39%;
}

.lineAndLogo_line {
  position: relative;
  margin-top: 204px;
  margin-left: 7%;
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, #ffffff00 61%, #2d98c8 50%);
}

.lineAndLogo_line img {
  margin-top: -29px;
  left: 22.8%;
  position: absolute;
  height: 60px;
  width: 60px;
}

.container_customersImages {
  width: 61%;
  float: right;
  margin-top: -205px;
}

.customers-img-mobile {
  display: none;
}

.cd-intro {
  font-size: 110pt;
  color: #3a3a3a;
  padding-left: 25px;
  line-height: 0.9;
  font-weight: 900;
  margin: 0 auto;
  margin-top: calc(90px - 60px);
  text-align: left;
}

.cd-words-wrapper b {
  color: transparent;
  background: -webkit-linear-gradient(131deg, #9315b9 12%, #2d98c8 33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cd-headline.rotate-1 .cd-words-wrapper {
  display: inline-block;
  perspective: 400px;
  width: 310px;
  font-size: 110pt;
}

.cd-headline.rotate-1 b {
  opacity: 0;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.cd-headline.rotate-1 b.is-visible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);
}

.cd-headline.rotate-1 b.is-visible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);
  animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
  transform: rotateX(180deg);
  animation: cd-rotate-1-out 1.2s;
}

@keyframes cd-rotate-1-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes cd-rotate-1-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}

.customers-img-1 {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.customers-img-2, .customers-img-3 {
  width: 100%;
  margin: -102px auto 0;
  text-align: center;
}

.customers-opacity {
  opacity: 1;
  display: inline-block;
  height: 200px;
  position: relative;
  z-index: 1;
}

.alien-wine, .beleduc, .davino, .exclusive-vets, .fyber, .hape, .indirim, .pica8, .rnts, .summerwood, .vonino, .vorbe-grele, .wayspa {
// background-image: url("../../assets/scriptics-sprite-desktop.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
  margin-left: 2px;
}

.wayspa {
  background-position: 0 -2306px;
}

.exclusive-vets {
  background-position: 0 -653px;
}

.fyber {
  background-position: 0 -2515px;
}

.rnts {
  background-position: 0 -2096px;
}

.hape {
  background-position: 0 -1886px;
}

.summerwood {
  background-position: 0 -1678px;
  background-color: #fafafa;
  z-index: 0;
}

.vonino {
  background-position: 0 0;
}

.pica8 {
  background-position: 0 -1049px;
}

.davino {
  background-position: 0 -419px;
}

.beleduc {
  background-position: 0 -1468px;
}

.exclusive-vets {
  background-position: 0 -629px;
}

.alien-wine {
  background-position: 0 -1259px;
}

.vorbe-grele {
  background-position: 0 -210px;
}

.indirim {
  background-position: 0 -839px;
}

.review-clutch {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
  gap: 55px;
  border: 2px solid red;
  margin-top: 5%;
  margin-left: 45%;
  padding: 2% 2%;
}

.review-clutch__content {
  padding: 10px 0;
}

.review-clutch-title {
  font-size: 38pt;
  color: #3a3a3a;
  font-weight: 500;
  line-height: 50px;
}

.clutch-widget {
  background-color: #FFFFFF;
}

.clutch-widget-text {
  color: red;
}
.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@media screen and (max-width: 1990px) {
  .partners-container {
  }
  .laptop-slider-content__slideshow {
    transform: scale(.8);
  }
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper {
    font-size: 90pt;
  }
  .cd-intro {
    margin-top: calc(119px - 60px);
  }

  .slick-dots {
    height: 10px;
  }
}

@media screen and (max-width: 1880px) {
  .partners-container {
 //  margin-top: 82em;
  }
}

@media screen and (max-width: 1660px) {
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper {
    font-size: 65pt;
  }
  .container_customersImages {
    width: 67%;
  }
  .animatedHeadline {
    width: 30%;
  }
  .cd-intro {
    margin-top: calc(161px - 60px);
  }
}

@media only screen and (max-width: 1530px) {
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper {
    font-size: 55pt;
  }
  .container_customersImages {
    width: 61%;
    zoom: 80%;
  }
  .cd-intro {
    margin-top: calc(136px - 60px);
  }
  .lineAndLogo_line {
    margin-top: 163px;
  }
}

@media only screen and (max-width: 1440px) {
  .partners-container {
  }
  .laptop-slider-grid {
    width: 100%;
  }
  .animatedHeadline {
    width: 32%;
  }
  .laptop-slider-content__slideshow {
    transform: scale(.7);
  }
  .container_customersImages {
    width: 65%;
  }
}

@media only screen and (max-width: 1300px) {

  .slideshow-container {
    margin: 0 auto;
    width: 80%;
  }
  .animatedHeadline {
    width: 100%;
    float: none;
    margin: 5% auto;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .cd-intro {
    text-align: center;
    margin-top: calc(15% - 41px);
  }
  .lineAndLogo_line {
    display: none;
  }
  .customers-container {
    margin-top: 0;
  }
  .container_customersImages {
    width: 100%;
    float: none;
    margin: 0 auto;
    clear: both;
    zoom: 0;
  }
  .customers-img-2, .customers-img-3 {
    width: 100%;
    margin: -90px auto 0;
    text-align: center;
  }
  .alien-wine, .beleduc, .davino, .exclusive-vets, .fyber, .hape, .indirim, .pica8, .rnts, .summerwood, .vonino, .vorbe-grele, .wayspa {
    //background-image: url("../../assets/scriptics-sprite-desktop.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 180px;
    width: 180px;
    margin-left: 4px;
  }
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper {
    font-size: 68pt;
  }
  .wayspa {
    background-position: 0 -2075px;
  }
  .exclusive-vets {
    background-position: 0 -653px;
  }
  .fyber {
    background-position: 0 -2264px;
  }
  .rnts {
    background-position: 0 -1886px;
  }
  .hape {
    background-position: 0 -1698px;
  }
  .summerwood {
    background-position: 0 -1510px;
  }
  .vonino {
    background-position: 0 0;
  }
  .pica8 {
    background-position: 0 -944px;
  }
  .davino {
    background-position: 0 -377px;
  }
  .beleduc {
    background-position: 0 -1321px;
  }
  .exclusive-vets {
    background-position: 0 -566px;
  }
  .alien-wine {
    background-position: 0 -1133px;
  }
  .vorbe-grele {
    background-position: 0 -189px;
  }
  .indirim {
    background-position: 0 -755px;
  }
}

@media only screen and (max-width: 1024px) {

  .review-clutch {
    width: 71%;
    margin: 5% auto;
  }
  .laptop-slider-content__slideshow {
    zoom: 77%;
  }
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper {
    font-size: 50pt;
  }
  .cd-headline.rotate-1 .cd-words-wrapper {
    width: 230px;
  }
}

@media only screen and (max-width: 1000px) {
  .cd-intro {
    margin-top: calc(25% - 41px);
    text-align: center;
  }

  .laptop-slider-content__title {
    font-size: 50px;
    background: rgb(58, 58, 58);
    background: linear-gradient(122deg, rgba(58, 58, 58, 1) 58%, rgba(45, 152, 200, 1) 64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .cd-headline.rotate-1 .cd-words-wrapper {
    min-width: 15% !important;
  }
  .customers-img-mobile {
    display: block !important;
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
  }
  .customers-img-mobile img {
    width: 100%;
    margin: 0 auto;
    background-color: #fafafa;
    text-align: center;
    align-items: center;
    align-content: center;
    webkit-box-shadow: 1px 6px 60px 8px rgba(0, 0, 0, .5);
    -moz-box-shadow: 1px 6px 60px 8px rgba(0, 0, 0, .5);
    box-shadow: 1px 6px 60px 8px rgba(0, 0, 0, .5);
  }
  .container_customersImages {
    padding: 12vh 0;
  }
  .customers-img-1, .customers-img-2, .customers-img-3 {
    display: none;
  }
}



@media only screen and (max-width: 768px) {

  .review-clutch {
    width: 83%;
  }

  .laptop-slider-grid .laptop-slider-content .slider span {
    height: 28vh!important;
  }
}

@media screen and (max-width: 425px) {
  .review-clutch {
    width: 90%;
    gap: 5px;
  }
  .review-clutch-title {
    font-size: 34pt;
    text-align: center;
  }
  .clutch-widget-text {
    font-size: 21pt;
  }
  .laptop-slider-grid .laptop-slider-content .slider .slider-box .slider-box__item {
    width: 70%;
  }
  .laptop-slider-grid .laptop-slider-content .timeline {
    display: none;
  }
  .laptop-slider-grid .laptop-slider-content .slider .slider-box {
    height: 21vh;
  }
  .laptop-slider-content__title {
    background: linear-gradient(125deg, #3a3a3a 58%, #2d98c8 63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .cd-intro, .cd-headline.rotate-1 .cd-words-wrapper, .laptop-slider-content__title {
    font-size: 55px;
  }
  .cd-headline.rotate-1 .cd-words-wrapper {
    width: 188px;
  }

  .cd-intro {
    margin-top: 0;
  }
}



</style>
