/* eslint-disable */
import axios from "axios";
import {CMS} from '@/main';

const state = () => ({
    employees: [],
});

const getters = {
    allEmployees: state => state.employees
};

const actions = {
    async loadEmployees({commit}) {
        try {
            const response = await axios.get(`${CMS}api/employees`);
            // const response = await axios.get(`http://localhost:8080/api/employees`);
            const data = await response.data;
            commit('setEmployees', data.employees)
        }
        catch (err) {
            throw new Error(err);
        }
    }
};

const mutations = {

    setEmployees: (state, employees) => {
        state.employees = employees;
    }

};

export default {
    state,
    getters,
    actions,
    mutations
}
