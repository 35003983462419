<template>
  <div>
      <i class="fas fa-times close-button " @click="closePanel()" />

    <div class="calendly-inline-widget" data-url="https://calendly.com/vali-badea/scriptics-meet-up"></div>
  </div>

</template>

<script>

export default {

  name: "Panel",

  data() {
    return {}
  },

  methods: {

    closePanel() {
      this.$emit("closePanel", {});
    },

  },

  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    document.head.appendChild(recaptchaScript);
  }
}
</script>

<style lang="scss" scoped>

.close-button {
  position: absolute;
  top: 5px;
  left: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #fafafa;
  z-index: 10002;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

}

.calendly-inline-widget {
  height: 55em;
  max-width: 850px;
}

@media only screen and (max-width: 600px) {
  .calendly-inline-widget {
    max-width: 550px;
    padding: 5vh;
  }
}
</style>
