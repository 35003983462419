<template>
  <header class="header">
      <menu-icon @click="toggleMenu($event)" :isActive="isHamburgerActive"></menu-icon>
      <overlay-menu @closeMenu="closeMenuHandler" :toggle-event="menuTriggerEvent" v-cloak>
        <div class="nav-list-links">
          <ul class="nav-list" v-for="element in images" v-bind:key="element.name">
            <li @mouseover="showImage(element)"
                @mouseleave="hideImage(element)">
              <router-link @click.native="toggleMenu($event)" :to="element.route" exact>
                {{ element.name }}
              </router-link>
              <img class="images-menu"
                   alt="images-menu"
                   v-show="element.active"
                   :src="element.picture">
            </li>
          </ul>
        </div>

        <div class="container-below-menu">
          <div class="container-below-menu-grid">
            <div class="container-below-menu__content">
              <div class="container-below-menu__content__left">
                <h3>{{ social }} </h3>
                <button v-on:click.prevent="showPanel($event)"
                        @mouseover="showImageRequest()"
                        @mouseleave="hideImageRequest()"
                        class="request-quote">Let&#39;s talk about your project!
                </button>
                <slideout-panel></slideout-panel>
                <img :src="requestQuote"
                     alt="requestQuote"
                     class="images-menu"
                     v-if="requestActive">

              </div>
              <div class="container-below-menu__content__right">
                <div class="container-below-menu__content__right-social-icon">
                  <a v-bind:href="linkedin" target="_blank">
                     <span class="social-icon">
                     <i class="fab fa-linkedin"></i>
                     </span>
                  </a>
                </div>

                <div class="container-below-menu__content__right-social-icon">
                  <a v-bind:href="instagram" target="_blank">
                    <span class="social-icon">
                    <i class="fab fa-instagram"></i>
                    </span>
                  </a>
                </div>

                <div class="container-below-menu__content__right-social-icon">
                  <a v-bind:href="facebook" target="_blank">
                    <span class="social-icon">
                    <i class="fab fa-facebook"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </overlay-menu>
  </header>
</template>

<script>
import home from '@/assets/hero-imgs/Scriptics-line.svg';
import portfolio from '@/assets/hero-imgs/scriptics-ipad-portfolio.svg';
import team from '@/assets/hero-imgs/scriptics-team-img.svg';
import contact from '@/assets/hero-imgs/scriptics-contact-img.svg';
import request from '@/assets/request-a-quote.svg';

import MenuIcon from '@/components/Menu/MenuIcon.vue';
import OverlayMenu from '@/components/Menu/Overlaymenu.vue';

import $ from 'jquery'

export default {

  name: "Menu",

  components: { MenuIcon, OverlayMenu,},
  data() {
    return {

      active: false,
      image: home, portfolio, team, contact,
      isScrolling: 0,
      menuTriggerEvent: null,
      request: 'Request a quote',
      social: 'Follow us',
      requestQuote: request,
      requestActive: false,
      linkedin: 'https://www.linkedin.com/company/scriptics/',
      instagram: 'https://www.instagram.com/scriptics/',
      facebook: 'https://www.facebook.com/Scriptics/',
      isHamburgerActive: false,
      panel1Form: {
        openOn: "right"
      },
      images: [
        {
          name: 'Home',
          picture: home,
          active: false,
          route: '/',
        },
        {
          name: 'Portfolio',
          picture: portfolio,
          active: false,
          route: '/portfolio',
        },
        {
          name: 'Team',
          picture: team,
          active: false,
          route: '/team',
        },
        {
          name: 'Contact',
          picture: contact,
          active: false,
          route: '/contact',
        }
      ],

    }
  },

  methods: {

    handleScroll() {
      this.isScrolling = (window.scrollY > 0);
    },

    showPanel(ev) {
      this.toggleMenu(ev);
      const panel = this.$showPanel({
        component: "Panel",
        cssClass: "Panel",
        cssClass: "calendar-panel",
        props: {}
      });
    },

    showImage: function (item) {
      item.active = true;
    },

    hideImage: function (item) {
      item.active = false;
    },

    showImageRequest: function () {
      this.requestActive = true;
    },

    hideImageRequest: function () {
      this.requestActive = false;
    },

    toggleMenu: function (ev) {
      this.menuTriggerEvent = ev;
      this.isHamburgerActive = !this.isHamburgerActive;
      if (this.isHamburgerActive) {
        $('.button-lets-chat').css('zIndex', '-1');
      }
      else {
        $('.button-lets-chat').css('zIndex', '9999');
      }
    },

    closeMenuHandler: function () {
      $('.button-lets-chat').css('zIndex', '9999');
      this.isHamburgerActive = false;

    },

  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.addEventListener('scroll', this.handleScroll);
  },

}
</script>

<style lang="scss">

.slideout-panel-bg {
  z-index: 10000 !important;
}

.calendar-panel, .gray-panel {
  z-index: 10001 !important;
}

body {
  width: 100%;
}

[v-cloak] {
  display: none;
}
.slideout-panel {
  .slideout-wrapper {
    .slideout.open-on-left.calendar-panel {
      right: auto;
      left: 0;
      background: #2d98c8;
      background: -moz-linear-gradient(-45deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
      background: -webkit-linear-gradient(-45deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
      background: linear-gradient(135deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
    }
    .slideout.open-on-left.gray-panel {
      right: auto;
      left: 0;
      background: rgb(104,108,113);
      background: linear-gradient(320deg, rgba(104,108,113,1) 28%, rgba(153,154,156,1) 100%);

    }
  }
}

.header {
  position: fixed;
  width: fit-content;
  z-index: 2048;
  top: 20px;
  right: 70px;
}
.header-inner {
}

.nav-list-links {
  position: relative;
  top: 45%;
}

.nav-list li a {
  position: relative;
  font-weight: 900;
  text-decoration: none;
  font-size: 55px;
  display: block;
  text-align: right;
  line-height: 100px;
  overflow: hidden;
  background: linear-gradient(to right, #62b4d4 50%, #3a3a3a 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 5;

  &:hover {
    background-position: 0 100%;
  }

  &:hover span::before {
    transform: translateX(0);
  }

  &:focus {
    outline: none;
  }

  .overlay-menu-active & {
    opacity: 1;
    transform: translateX(0);
  }
}

.images-menu {
  left: 7%;
  top: 30%;
  width: 20%;
  height: 38%;
  position: fixed;
  z-index: 5;

}

.container-below-menu {
  position: relative;
  top: 20em;
  overflow: hidden;
}

.container-below-menu-grid {
  max-width: 1400px;
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  position: relative;
  z-index: 5;

}

.container-below-menu__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3em 0;
}

.container-below-menu__content__left, .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  float: left;
  width: 100%;
  font-weight: 900;
  color: #2d98c8;
}

.container-below-menu__content__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.container-below-menu__content__right-social-icon {
  width: 30px;
  margin: 0 10px;
}

i:hover {
  transition: all .9s ease 0s;
  opacity: .6;
}

.request-quote {
  color: #c25168;
  display: inline-block;
  position: relative;
  text-decoration: none;
  justify-content: space-between;
  padding-left: 10px;
  font-weight: 900;
}

.request-quote:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 10px;
  background-color: #c25168;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.request-quote:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

ul li {
  list-style: none;
}

.slideout-panel .slideout-wrapper .slideout.open-on-left {
  right: auto;
  left: 0;
  background: #2d98c8;
  background: -moz-linear-gradient(-45deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
  background: -webkit-linear-gradient(-45deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
  background: linear-gradient(135deg, #2d98c8 0%, rgba(142, 68, 173, 1) 100%);
}

@media only screen and (max-width: 2560px) {
  .nav-list li a {
    font-size: 80px;
    line-height: 140px;
  }
  .container-below-menu__content__right-social-icon {
    margin: 10px 5px;
  }
  .overlay-menu-content {
    width: 80%;
  }
  .container-below-menu-grid {
    left: 129px;
  }
  .container-below-menu__content__left {
    font-size: 22px;
  }
  .social-icon {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1990px) {
  .nav-list li a {
    font-size: 70px;
    line-height: 100px;
  }
  .container-below-menu-grid {
    left: 118px;
  }
  .social-icon {
    font-size: 25px;
  }
  .container-below-menu__content__right-social-icon {
    margin: 10px 3px;
  }
  .container-below-menu {
    top: 16em;
  }
}

@media only screen and (max-width: 1440px) {
  .nav-list-links {
    top: 30%;
  }
  .nav-list li a {
    line-height: 110px;
  }
  .container-below-menu {
    top: 10em;
  }
}

@media only screen and (max-width: 768px) {
  .nav-list li a {
    font-size: 45px;
    line-height: 80px;
  }
  .container-below-menu__content__left {
    font-size: 17px;
  }
  .container-below-menu__content__right-social-icon {
    margin: 10px 3px;
  }
}

@media screen and (max-width: 425px) {

  .nav-list li a {
    text-align: center;
    font-size: 31px;
  }

  .overlay-menu-content {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    .nav-list-links {
      top: unset;
    }

    .container-below-menu {
      top: unset;
      margin-top: 40px;

      &-grid {
        float: unset;
        left: unset;
      }

      &__content {
        flex-direction: column;
        flex-wrap: unset;
        padding: 0;

        &__left {
          float: unset;
          flex-direction: column-reverse;

          h3 {
            top: unset;
            left: unset;
            width: unset;
            margin: 20px 0;
          }

          .request-quote {
            padding: 5px 10px;
            color: #fafafa;
            border-radius: 50px;
            background-color: #c25168;
          }

        }
        &__right {
          left: unset;
          padding: 0;
          &-social-icon {
            margin: 0 3px;
          }
        }
      }
    }

  }

  .container-below-menu__content__left {
    font-size: 18px;
  }

  .social-icon {
    font-size: 30px;
  }

  .images-menu {
    display: none;
  }

  @-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }

    to {
      -webkit-mask-position: -50%;
    }
  }

  .request-quote:hover {
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
  }

  .request-quote:after {
    display: none;
  }

  .container-below-menu__content__left h3 {
    position: relative;
    top: 15px;
    left: 190px;
    width: 14em;
  }

  .container-below-menu__content__right {
    position: relative;
    left: 185px;
    padding: 2vh 0;
  }

}

</style>
