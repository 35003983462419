<template>
  <div>
    <i class="fas fa-times close-button" @click="closePanel()"/>

    <div class="cookies-policy">
      <span class="cookies-policy__title bold">COOKIE POLICY</span>
      <span class="cookies-policy__answer">
      SCRIPTICS DECISIONS SRL understand that your privacy is important to you and are committed to being transparent about the technologies it uses. This Cookie Policy explains how and why cookies may be stored on and accessed from your device when you use or visit our website (scriptics.ro). Please read this Cookie Policy carefully before using our Site.
      </span>

      <p class="cookies-policy__accept bold">
        By clicking "Accept" on the Cookie banner, you give us your consent to store and access Cookies and Other Tracking Technologies as described in this Cookie Policy.
      </p>

      <span class="cookies-policy__question bold">What are Cookies?</span>
      <div class="cookies-policy__answer">
        <p>
                Cookies are small text files, often including unique identifiers, that are sent by web servers to web browsers,
      and which may then be sent back to the server each time the browser requests a page from the server.
        </p>

        <p>
          Cookies are very useful and enable an internet site to recognize you, log when you visit a particular page,
          provide a secure connection to a website and enhance your user experience by: improving your browsing comfort,
          and/ or adapting the content of a page to your areas of interest.
        </p>

      </div>

      <span class="cookies-policy__question bold">How does our Site use Cookies?</span>
      <p class="cookies-policy__answer">
        We may use information collected from our Cookies to identify user behavior and to serve content and offers based
      on your profile, and in order to enhance the convenience of the users of our Site.
      </p>

      <span class="cookies-policy__question bold">Information on the Types of Cookies that are used on our Site</span>
      <p class="cookies-policy__answer">
        We have provided details of the types of Cookies that are used on our Site as well as other relevant and useful
      information on the Cookies. You can also find more information on the Cookies when you click the pop-up window.
      </p>


      <span class="cookies-policy__question bold">-Technical Cookies:</span>
      <p class="cookies-policy__answer">
        These Cookies are necessary to enable you to move around our Site and use its features. These Cookies also allow
      our Site to remember your previous action within the same browsing session.
      </p>

      <span class="cookies-policy__question bold">-Analytical Cookies:</span>
      <p class="cookies-policy__answer">
        These Cookies are used by us or third-party service providers to analyze how our Sites are used and how they are
      performing. For example, we use cookies to know which country you are in when you request a service to help base
      the contents on your location. We also use some Cookies from Google Analytics and SiTest to obtain
      web analytics and intelligence about our Site. These tools use programming code to collect information about your
      interaction with our Site, such as the pages you visit, the links you click on and how long you are on our Site.
      These tools do not store any personal identifiable information.
      </p>

      <span class="cookies-policy__question bold">-Functionality Cookies:</span>
      <p class="cookies-policy__answer">
        These Cookies allow our Site to remember choices you make when you visit our Site in order to provide enhanced,
      more personalized features to you. The types of information collected by Functionality Cookies are, language
      preference, and the region you are located in.
      </p>

      <span class="cookies-policy__question bold">-Advertising and Retargeting Cookies:</span>
      <p class="cookies-policy__answer">
        These Cookies save information from your browsing history in order to record your interests and your browsing path
      on our Site. We may use advertising and targeting cookies for retargeting, which enables our advertising partners
      to target advertising to you on other websites, based on your visit to our Site. Without these Cookies, online
      advertisements you encounter will be less relevant to you and your interests.
      </p>


      <span class="cookies-policy__question bold">How do I refuse or withdraw my consent to the use of cookies?</span>
      <p class="cookies-policy__answer">
        If you do not want Cookies to be placed on your device, we suggest you to set your preferences regarding Cookies.
      By clicking "Cookie Preferences" on the bottom of each webpage, you can select the types of Cookies our Site uses.
      You can change your preferences at any time; you can also withdraw the consent you have previously given to us.
      If you wish to remove previously-stored Cookies, you can manually delete the Cookies at any time. However, this
      will not prevent our Site from placing further Cookies on your device unless and until you adjust your "Cookie
      Preferences" choice as described above.
      </p>

      <span class="cookies-policy__question bold">Contact Details</span>
      <p class="cookies-policy__answer">
        If you have any other questions or comments about our Cookie Policy, please contact us by sending an email to
      contact@scriptics.ro
      </p>

    </div>

  </div>

</template>

<script>

export default {

  name: "CookiesPanel",

  data() {
    return {}
  },

  methods: {

    closePanel() {
      this.$emit("closePanel", {});
    },

  },

  mounted() {

  },

}
</script>

<style lang="scss" scoped>

.close-button {
  position: absolute;
  top: 5px;
  left: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #fafafa;
  z-index: 10002;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

}

.cookies-policy {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  color: #333;
  margin: 10%;
  padding: 20px;
  border-radius: 20px;
  line-height: 1.5em;
  -webkit-box-shadow: 0 0 15px -4px #ffffff;
  box-shadow: 0 0 15px -4px #ffffff;

  span {
    pointer-events: none;
  }

  .bold {
    font-weight: bold;
  }

  &__title {
    margin-bottom: 35px;
    font-size: 1.5em;
  }

  &__accept {
    margin: 20px 0;
  }

  &__answer, &__accept {
    font-size: 0.8em;
  }

  &__question {
    font-size: 1.5em;
    margin-bottom: 12px;
  }

  &__accept {
    margin-bottom: 25px;
  }

}

//.calendly-inline-widget {
//  height: 55em;
//  max-width: 850px;
//}
//
//@media only screen and (max-width: 600px) {
//  .calendly-inline-widget {
//    max-width: 550px;
//    padding: 5vh;
//  }
//}
</style>
