import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import employees from './modules/employees';
import notification from "@/store/modules/notification";
import partners from "@/store/modules/partners";
import customers from "@/store/modules/customers";


export default new Vuex.Store({
    modules: {
        employees,
        notification,
        partners,
        customers
    }
});
